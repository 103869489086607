<script>
export default {
  components: {
    StructureBinaryItem: () =>
      import("@/components/structure/StructureBinaryItem.vue"),
  },

  props: {
    children: {
      required: true,
    },

    firstChild: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      required: true,
    },

    notEmptyLevel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasChildren() {
      return (
        !!this.children?.left?.children || !!this.children?.right?.children
      );
    },

    currentLevel() {
      return this.children.right !== null && this.firstChild
        ? this.children.right.level
        : this.children.left !== null && this.firstChild
        ? this.children.left.level
        : this.user.level === 0
        ? 1
        : 3;
    },
  },
};
</script>

<template>
  <div
    class="legs"
    :class="[{ legs_small: !hasChildren }, `legs_level-${currentLevel}`]"
  >
    <img
      v-if="user.level === 0 && user.strong_direction === 'left'"
      class="legs__line"
      src="../assets/legs_line_level_1.svg"
      alt=""
    />
    <img
      v-else-if="user.level === 0 && user.strong_direction === 'right'"
      class="legs__line"
      src="../assets/legs_line_level_1_reflected.svg"
      alt=""
    />
    <img v-else class="legs__line" src="../assets/legs_line.svg" alt="" />

    <div class="legs__left">
      <structure-binary-item
        :user="children.left"
        :first-child="currentLevel === 1"
        :not-empty-level="notEmptyLevel"
        :current-level="currentLevel"
      />
    </div>
    <div class="legs__right">
      <structure-binary-item
        :user="children.right"
        :first-child="currentLevel === 1"
        :not-empty-level="notEmptyLevel"
        :current-level="currentLevel"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.legs {
  display: flex;
  justify-content: space-between;
  position: relative;

  &__line {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__left,
  &__right {
    position: relative;
    height: 60px;
    width: 60px;
  }

  &__left {
    left: -32px;
  }

  &__right {
    right: -32px;
  }

  &_small {
    height: 94px !important;

    .legs__left,
    .legs__right {
      height: 40px;
      width: 40px;
    }

    .legs__left {
      left: -20px;
    }

    .legs__right {
      right: -20px;
    }
  }

  &_level-1 {
    width: 425px;
    height: 22px;

    .legs__left,
    .legs__right {
      top: 32px;
    }
  }

  &_level-2 {
    width: 180px;

    .legs__left,
    .legs__right {
      top: 56px;
    }
  }

  &_level-3 {
    width: 120px;

    .legs__left,
    .legs__right {
      top: 38px;
    }
  }
}
</style>
