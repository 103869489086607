<script>
import { mapState, mapGetters } from "vuex";
import StructureBinaryUser from "@/components/structure/StructureBinaryUser.vue";
import LegsComponent from "@/components/LegsComponent.vue";
import UserPreview from "@/components/UserPreview.vue";

const EMPTY_CHILDREN = {
  left: null,
  right: null,
};

export default {
  components: {
    LegsComponent,
    StructureBinaryUser,
    UserPreview,
  },

  props: {
    user: {
      required: true,
    },

    firstChild: {
      type: Boolean,
      default: false,
    },

    notEmptyLevel: {
      type: Boolean,
      default: false,
    },

    currentLevel: {
      type: Number,
      default: 1,
    },

    isParent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      EMPTY_CHILDREN,
    };
  },

  computed: {
    ...mapState({
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    ...mapGetters({
      getUserBinaryAccounts: "auth/getUserBinaryAccounts",
    }),

    hasChildren() {
      return !!this.user?.children;
    },

    isSmallImage() {
      return (
        (!this.notEmptyLevel && !this.firstChild) || this.currentLevel === 3
      );
    },

    formattedUserPreviewInfo() {
      let name;

      if (
        this.getUserBinaryAccounts.find((binaryAccount) => {
          return binaryAccount.id === this.user.id;
        }) !== undefined
      ) {
        name = "Ваш аккаунт";
      } else {
        name = `${this.user.distributor.first_name} ${this.user.distributor.last_name}`;
      }

      return {
        id: this.user.id,
        name: name,
        tariff: {
          code: this.user.tariff_code,
        },
        qualification: {
          code: this.user.qualification_code,
        },
      };
    },
  },
};
</script>

<template>
  <div class="structure-binary-item">
    <template v-if="user">
      <user-preview
        v-if="isParent"
        class="structure-binary-item__profile"
        :user="user"
        :binary-account="formattedUserPreviewInfo"
      />

      <structure-binary-user
        v-else
        class="structure-binary-item__user"
        :user="user"
        :small-image="!hasChildren && !firstChild"
      />

      <div v-if="hasChildren" class="structure-binary-item__legs">
        <legs-component
          :user="user"
          :children="user.children"
          :first-child="firstChild"
          :not-empty-level="true"
        />
      </div>

      <div v-else-if="firstChild" class="structure-binary-item__legs">
        <legs-component
          :user="user"
          :children="EMPTY_CHILDREN"
          :first-child="firstChild"
        />
      </div>
    </template>

    <template v-else>
      <div
        class="structure-binary-item__img"
        :class="{
          'structure-binary-item__img_small': isSmallImage,
        }"
      >
        <img src="../../assets/images/no_user.svg" alt="" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.structure-binary-item {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__profile {
    margin-bottom: $space-xl;
  }

  &__img {
    height: 60px;
    width: 60px;

    > img {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &_small {
      height: 40px;
      width: 40px;
    }
  }
}
</style>
