var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"structure__wrapper"},[_c('div',{staticClass:"account-selector-wrapper"},[_c('AccountSelector')],1),_c('div',{staticClass:"structure default-content-padding"},[_c('div',{staticClass:"structure__content"},[_c('div',{staticClass:"structure__invited-persons-info"},[_c('NewUsersStatistic',{staticClass:"structure__new-users-statistic"}),(_vm.tariffsOfBinaryAccount)?_c('TariffsOfBinaryAccount',{staticClass:"structure__tariffs-of-binary-account",attrs:{"tariffs-of-binary-account":_vm.tariffsOfBinaryAccount}}):_vm._e(),_c('div',{staticClass:"structure__leg-selector"},[_c('p',[_vm._v(" Выберите ногу, в которую будут вставать приглашенные вами партнеры (с тарифа Клиент Смарт) ")]),_c('LegSelector',{attrs:{"show-title":false}})],1)],1),(_vm.showStructure)?[_c('MainTabs',{staticClass:"structure__tabs",attrs:{"selected-tab":_vm.selectedStructure,"tabs":_vm.structures},on:{"update:selectedTab":function($event){_vm.selectedStructure=$event},"update:selected-tab":function($event){_vm.selectedStructure=$event}}}),(_vm.selectedStructure.id === 'binary')?_c('StructureBinaryTabs',{staticClass:"structure__binary-tabs",attrs:{"selected-tab":_vm.selectedBinaryStructure,"tabs":_vm.binaryStructures},on:{"update:selectedTab":function($event){_vm.selectedBinaryStructure=$event},"update:selected-tab":function($event){_vm.selectedBinaryStructure=$event}}}):_vm._e(),_c('div',{staticClass:"structure__type"},[(
              _vm.selectedStructure.id === 'binary' &&
              _vm.selectedBinaryStructure.id === 'graph'
            )?_c('StructureBinary',{staticClass:"structure__binary"}):_vm._e(),(
              _vm.selectedStructure.id === 'binary' &&
              _vm.selectedBinaryStructure.id === 'list'
            )?_c('StructureList'):_vm._e(),(_vm.selectedStructure.id === 'line')?_c('StructureLine'):_vm._e(),(_vm.selectedStructure.id === 'free')?_c('StructureFree'):_vm._e()],1)]:[_c('div',{staticClass:"structure__tariff-buy"},[_vm._m(0),_c('MainButton',{attrs:{"color":"gold","title":"Сменить тариф"},nativeOn:{"click":function($event){return _vm.$router.push('/tariffs')}}})],1)]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Чтобы видеть свою структуру, необходим тариф "),_c('span',[_vm._v("Бизнес")]),_vm._v(" или выше ")])
}]

export { render, staticRenderFns }