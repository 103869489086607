<script>
import { mapActions, mapState } from "vuex";
import { eventBus } from "@/main";
import StructureLine from "@/components/structure/StructureLine";
import MainButton from "@/components/helpers/MainButton";
import StructureBinary from "@/components/structure/StructureBinary.vue";
import AccountSelector from "@/components/AccountSelector";
import LegSelector from "@/components/LegSelector.vue";
import MainTabs from "@/components/helpers/MainTabs";
import StructureFree from "@/components/structure/StructureFree";
import StructureList from "@/components/structure/StructureList.vue";
import StructureBinaryTabs from "@/components/structure/StructureBinaryTabs.vue";
import TariffsOfBinaryAccount from "@/components/TariffsOfBinaryAccount.vue";
import NewUsersStatistic from "@/components/dashboard/NewUsersStatistic.vue";

export default {
  name: "Structure",

  components: {
    MainTabs,
    AccountSelector,
    StructureBinary,
    MainButton,
    StructureLine,
    LegSelector,
    StructureFree,
    StructureBinaryTabs,
    StructureList,
    TariffsOfBinaryAccount,
    NewUsersStatistic,
  },

  data() {
    return {
      selectedBinaryStructure: {
        id: "graph",
        title: "график",
        icon: "icon-binar",
      },

      binaryStructures: [
        {
          id: "graph",
          title: "график",
          icon: "icon-binar",
        },
        {
          id: "list",
          title: "список",
          icon: "icon-list",
        },
      ],

      selectedStructure: {
        id: "binary",
        title: "Структура",
        icon: "icon-binar",
      },

      structures: [
        {
          id: "binary",
          title: "Структура",
          icon: "icon-binar",
        },
        {
          id: "line",
          title: "Линейная структура",
          icon: "icon-line",
        },
        {
          id: "free",
          title: "Клиенты Фри",
          icon: "icon-person",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      innerWidth: (state) => state.innerWidth,
      tariffsOfBinaryAccount: (state) => state.account.tariffsOfBinaryAccount,
    }),

    showStructure() {
      return (
        this.user?.tariff &&
        this.user.tariff.code !== "smart_c" &&
        this.user.tariff.code !== "standart_c"
      );
    },
  },

  watch: {
    innerWidth() {
      const line = this.structures.find((el) => {
        return el.id === "line";
      });

      if (this.innerWidth < 900) {
        line.title = "Линейная";
      } else {
        line.title = "Линейная структура";
      }

      if (this.selectedStructure.id === "line") {
        this.selectedStructure = line;
      }
    },
  },

  methods: {
    ...mapActions({
      loadTariffsOfBinaryAccount: "account/loadTariffsOfBinaryAccount",
    }),
  },

  mounted() {
    this.loadTariffsOfBinaryAccount(this.selectedBinaryAccount.id);
  },

  created() {
    eventBus.$on("showInviterBinary", (id) => {
      this.selectedStructure = {
        id: "binary",
        title: "Структура",
        icon: "icon-binar",
      };

      this.$nextTick(() => {
        eventBus.$emit("selectTreeItem", id);
      });
    });
  },
};
</script>

<template>
  <div class="structure__wrapper">
    <div class="account-selector-wrapper">
      <AccountSelector />
    </div>

    <div class="structure default-content-padding">
      <div class="structure__content">
        <div class="structure__invited-persons-info">
          <NewUsersStatistic class="structure__new-users-statistic" />

          <TariffsOfBinaryAccount
            v-if="tariffsOfBinaryAccount"
            class="structure__tariffs-of-binary-account"
            :tariffs-of-binary-account="tariffsOfBinaryAccount"
          />

          <div class="structure__leg-selector">
            <p>
              Выберите ногу, в которую будут вставать приглашенные вами партнеры
              (с тарифа Клиент Смарт)
            </p>

            <LegSelector :show-title="false" />
          </div>
        </div>

        <template v-if="showStructure">
          <MainTabs
            class="structure__tabs"
            :selected-tab.sync="selectedStructure"
            :tabs="structures"
          />

          <StructureBinaryTabs
            v-if="selectedStructure.id === 'binary'"
            class="structure__binary-tabs"
            :selected-tab.sync="selectedBinaryStructure"
            :tabs="binaryStructures"
          />

          <div class="structure__type">
            <StructureBinary
              v-if="
                selectedStructure.id === 'binary' &&
                selectedBinaryStructure.id === 'graph'
              "
              class="structure__binary"
            />

            <StructureList
              v-if="
                selectedStructure.id === 'binary' &&
                selectedBinaryStructure.id === 'list'
              "
            />

            <StructureLine v-if="selectedStructure.id === 'line'" />

            <StructureFree v-if="selectedStructure.id === 'free'" />
          </div>
        </template>

        <template v-else>
          <div class="structure__tariff-buy">
            <p>
              Чтобы видеть свою структуру, необходим тариф
              <span>Бизнес</span> или выше
            </p>
            <MainButton
              color="gold"
              title="Сменить тариф"
              @click.native="$router.push('/tariffs')"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.structure {
  background-color: $background-white;
  overflow: auto;
  padding-top: 48px;
  padding-bottom: 48px;

  &__content {
    max-width: 800px;
  }

  &__invited-persons-info {
    display: flex;
    flex-wrap: wrap;
    row-gap: 64px;
    column-gap: 96px;
    margin-bottom: 64px;
  }

  &__new-users-statistic {
    width: 100%;
  }

  &__tariffs-of-binary-account {
    max-width: 320px;
  }

  &__leg-selector {
    max-width: 320px;

    p {
      margin-bottom: 12px;
      @include text-2;
      color: $dark-fifth;
      max-width: 404px;
    }
  }

  &__tabs {
    margin-bottom: 32px;
    max-width: 645px;
  }

  &__binary-tabs {
    margin-bottom: 32px;
  }

  &__tariff-buy {
    p {
      @include body-1;
      color: $dark-primary;
      max-width: 416px;
      margin-bottom: $space-xl;

      span {
        @include body-1-bold;
      }
    }

    .main-button {
      max-width: 300px;
    }
  }

  &__selector {
    margin-bottom: $space-xl;
  }
}

@media (max-width: 1280px) {
  .structure {
    &__invited-persons-info {
      flex-direction: column;
    }
  }
}

@media (max-width: 576px) {
  .structure {
    &__invited-persons-info {
      margin-bottom: 48px;
      row-gap: 48px;
    }
  }
}

@media (max-width: 425px) {
  .structure {
    &__invited-persons-info {
      margin-bottom: 32px;
      row-gap: 32px;
    }
  }
}
</style>
