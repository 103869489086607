export const STRUCTURE_FREE_INVITED_TYPES = {
  all: {
    text: "Все",
    value: 0,
  },
  self: {
    text: "Лично",
    value: 0,
  },
  distributor: {
    text: "Сеть",
    value: 0,
  },
  tsp: {
    text: "ТСП",
    value: 0,
  },
  struct_tsp: {
    text: "ТСП сеть",
    value: 0,
  },
};
