<script>
import { format } from "date-fns";
import TariffPreview from "@/components/TariffPreview.vue";
import QualificationPreview from "@/components/QualificationPreview.vue";

export default {
  components: {
    TariffPreview,
    QualificationPreview,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedName() {
      return !this.isDisabledUser
        ? this.user.distributor.first_name +
            " " +
            this.user.distributor.last_name
        : "Не активирован";
    },

    formattedPosition() {
      switch (this.user.relative_position) {
        case "left":
          return "L";

        case "right":
          return "R";

        default:
          return "-";
      }
    },

    formattedRegistrationDate() {
      return this.user?.person_registration_date
        ? format(new Date(this.user.person_registration_date), "dd.MM.yyyy")
        : "нет данных";
    },

    formattedRepresentation() {
      if (this.user.distributor.representation?.city) {
        return `${this.user.distributor.representation.city}, ${this.user.distributor.representation.address}`;
      } else {
        return `${this.user.distributor.representation.address}`;
      }
    },

    isDisabledUser() {
      return this.user.is_disabled;
    },

    showDistributorRepresentation() {
      return !!this.user?.distributor?.representation;
    },
  },
};
</script>

<template>
  <div class="structure-line-item">
    <div class="structure-line-item__left">
      <div class="structure-line-item__img">
        <img v-if="!isDisabledUser && user.avatar" :src="user.avatar" alt="" />

        <img
          v-else-if="!isDisabledUser"
          src="../../assets/images/user_no_img.svg"
          height="40"
          width="40"
          alt=""
        />

        <img
          v-else
          src="../../assets/images/user_not_activated.svg"
          height="40"
          width="40"
          alt=""
        />
      </div>

      <div class="structure-line-item__user user">
        <h6
          class="user__name"
          :class="{ 'user__name_not-activated': isDisabledUser }"
        >
          {{ formattedName }}
        </h6>
        <p class="user__id">{{ user.id }}</p>
      </div>
    </div>

    <div v-if="!isDisabledUser" class="structure-line-item__right right">
      <div class="right__top">
        <qualification-preview
          class="structure-line-item__qualification"
          :qualification-code="user.qualification_code"
        />

        <tariff-preview
          class="structure-line-item__tariff"
          :tariff-code="user.tariff_code"
        />
      </div>

      <p class="structure-line-item__registration-date">
        Регистрация {{ formattedRegistrationDate }}
      </p>

      <p
        v-if="showDistributorRepresentation"
        class="structure-line-item__representation"
      >
        {{ formattedRepresentation }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.structure-line-item {
  padding: $space-m;
  background-color: $light-second;
  border-radius: $space-s;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: $space-s;
  }

  @media (max-width: 632px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__left {
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: $space-s;
    align-items: center;
    margin-right: $space-l;

    @media (max-width: 632px) {
      margin-bottom: $space-s;
    }
  }

  &__img {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
  }

  .user {
    &__name {
      @include text-2-bold;
      color: $dark-primary;
      margin-bottom: $space-xxs;

      &_not-activated {
        @include text-2;
        color: $red;
      }
    }

    &__id {
      @include caption-1;
      color: $dark-sixth;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 632px) {
      align-items: flex-start;
      padding-left: 25px;
    }
  }

  .right {
    &__top {
      display: flex;

      @media (max-width: 425px) {
        flex-direction: column;

        .structure-line-item__qualification {
          margin-bottom: $space-xs;
        }
      }
    }
  }

  &__qualification {
    margin-right: $space-xs;
  }

  &__registration-date {
    @include caption-1;
    color: $dark-sixth;
    margin-top: $space-s;
  }

  &__representation {
    @include caption-1;
    color: $dark-sixth;
    margin-top: $space-xs;
    max-width: 300px;
    text-align: end;
  }
}
</style>
