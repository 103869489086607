<script>
import vClickOutside from "v-click-outside";
import { eventBus } from "@/main";
import { format } from "date-fns";
import QualificationPreview from "@/components/QualificationPreview";
import TariffPreview from "@/components/TariffPreview";
import MainButton from "@/components/helpers/MainButton";
import TooltipInfo from "@/components/tooltips/TooltipInfo";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    QualificationPreview,
    TariffPreview,
    MainButton,
    TooltipInfo,
  },

  props: {
    user: {
      required: true,
    },

    smallImage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showTooltip: false,
    };
  },

  computed: {
    formattedRegistrationDate() {
      return this.user?.person_registration_date
        ? format(new Date(this.user.person_registration_date), "dd.MM.yyyy")
        : "нет данных";
    },

    showUserImage() {
      return !this.user.is_disabled && this.user?.avatar;
    },

    formattedCV() {
      return `${this.user.cv_left}/${this.user.cv_right}`;
    },
  },

  methods: {
    hideTooltip() {
      this.showTooltip = false;
    },

    selectTreeItem(id) {
      eventBus.$emit("selectTreeItem", id);
    },
  },
};
</script>

<template>
  <div
    v-click-outside="hideTooltip"
    class="structure-binary-user"
    :class="[
      { 'structure-binary-user_bordered': showTooltip },
      { 'structure-binary-user_small-image': smallImage },
    ]"
    @click="showTooltip = true"
  >
    <img v-if="showUserImage" :src="user.avatar" alt="" />

    <img
      v-else-if="!user.is_disabled"
      src="../../assets/images/user_no_img.svg"
      alt=""
    />

    <img v-else src="../../assets/images/user_not_activated.svg" alt="" />

    <template v-if="!user.is_disabled">
      <tooltip-info
        v-if="showTooltip"
        class="structure-binary-user__tooltip"
        :title="user.distributor.first_name + ' ' + user.distributor.last_name"
        :description="user.id"
        @close="showTooltip = false"
      >
        <p class="structure-binary-user__registration-date">
          Регистрация {{ formattedRegistrationDate }}
        </p>

        <div class="structure-binary-user__info">
          <qualification-preview
            class="structure-binary-user__qualification"
            :qualification-code="user.qualification_code"
          />

          <tariff-preview
            class="structure-binary-user__tariff"
            :tariff-code="user.tariff_code"
          />

          <div class="structure-binary-user__pv-cv">
            <p class="structure-binary-user__pv">
              {{ user.pv }} <span>PV</span>
            </p>
            <p class="structure-binary-user__cv">
              {{ formattedCV }} <span>CV</span>
            </p>
          </div>
        </div>

        <main-button
          class="structure-binary-user__button"
          title="К бинарному дереву"
          color="dark"
          @click.native="selectTreeItem(user.id)"
        />
      </tooltip-info>
    </template>

    <template v-else>
      <tooltip-info
        v-if="showTooltip"
        class="structure-binary-user__tooltip structure-binary-user__tooltip_not-activated"
        @close="showTooltip = false"
      >
        <h6>Не активирован</h6>
        <p>{{ user.id }}</p>

        <main-button
          class="structure-binary-user__button"
          title="К бинарному дереву"
          color="dark"
          @click.native="selectTreeItem(user.id)"
        />
      </tooltip-info>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.structure-binary-user {
  position: relative;
  margin-bottom: $space-xs;

  > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
  }

  &_bordered {
    > img {
      outline: 3px solid $gold-main;
    }
  }

  &_small-image {
    > img {
      width: 40px;
      height: 40px;
    }
  }

  &__tooltip {
    left: calc(100% + 12px);
    top: 0;

    &_not-activated {
      h6 {
        @include text-2;
        color: $red;
        margin-bottom: $space-xs;
      }

      p {
        @include caption-1;
        color: $dark-sixth;
        margin-bottom: $space-m;
      }
    }
  }

  &__button {
    margin-top: $space-m;
    max-width: 270px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__tariff {
    background-color: $light-second;
  }

  &__registration-date {
    @include caption-1;
    color: $dark-sixth;
    margin-bottom: 8px;
    margin-top: 12px;
  }

  &__pv-cv {
    background-color: $light-second;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    p {
      @include caption-1;
      color: $dark-primary;
    }

    span {
      @include caption-1-bold;
    }
  }

  &__pv {
    span {
      color: $money-pv;
    }
  }

  &__cv {
    span {
      color: $money-cv;
    }
  }
}
</style>
