<script>
import { format } from "date-fns";
import TariffPreview from "@/components/TariffPreview.vue";
import QualificationPreview from "@/components/QualificationPreview.vue";

export default {
  components: {
    TariffPreview,
    QualificationPreview,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedName() {
      return !this.isDisabledUser
        ? this.user.distributor.first_name +
            " " +
            this.user.distributor.last_name
        : "Не активирован";
    },

    formattedPosition() {
      switch (this.user.relative_position) {
        case "left":
          return "L";

        case "right":
          return "R";

        default:
          return "-";
      }
    },

    formattedRegistrationDate() {
      return this.user?.person_registration_date
        ? format(new Date(this.user.person_registration_date), "dd.MM.yyyy")
        : "нет данных";
    },

    isDisabledUser() {
      return this.user.is_disabled;
    },

    formattedCV() {
      return `${this.user.cv_left}/${this.user.cv_right}`;
    },
  },
};
</script>

<template>
  <div class="structure-list-item">
    <div class="structure-list-item__left">
      <span class="structure-list-item__leg">{{ formattedPosition }}</span>

      <div class="structure-list-item__img">
        <img v-if="!isDisabledUser && user.avatar" :src="user.avatar" alt="" />

        <img
          v-else-if="!isDisabledUser"
          src="../../assets/images/user_no_img.svg"
          height="48"
          width="48"
          alt=""
        />

        <img
          v-else
          src="../../assets/images/user_not_activated.svg"
          height="48"
          width="48"
          alt=""
        />
      </div>

      <div class="structure-list-item__user user">
        <h6
          class="user__name"
          :class="{ 'user__name_not-activated': isDisabledUser }"
        >
          {{ formattedName }}
        </h6>
        <p class="user__id">{{ user.id }}</p>
        <p class="user__registration-date">
          Регистрация {{ formattedRegistrationDate }}
        </p>
      </div>
    </div>

    <div v-if="!isDisabledUser" class="structure-list-item__right right">
      <div class="right__top">
        <qualification-preview
          class="structure-list-item__qualification"
          :qualification-code="user.qualification_code"
        />

        <tariff-preview
          class="structure-list-item__tariff"
          :tariff-code="user.tariff_code"
        />
      </div>

      <div class="structure-list-item__pv-cv">
        <p class="structure-list-item__pv">{{ user.pv }} <span>PV</span></p>
        <p class="structure-list-item__cv">{{ formattedCV }} <span>CV</span></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.structure-list-item {
  padding: $space-m;
  background-color: $light-second;
  border-radius: $space-s;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: $space-s;
  }

  @media (max-width: 632px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__left {
    display: grid;
    grid-template-columns: 17px 48px 1fr;
    column-gap: $space-s;
    align-items: center;
    margin-right: $space-l;

    @media (max-width: 632px) {
      margin-bottom: $space-m;
    }
  }

  &__leg {
    @include body-1-bold();
    color: $dark-fifth;
  }

  &__img {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
  }

  .user {
    &__name {
      @include text-2-bold;
      color: $dark-primary;
      margin-bottom: $space-xxs;

      &_not-activated {
        @include text-2;
        color: $red;
      }
    }

    &__id {
      @include caption-1;
      color: $dark-sixth;
    }

    &__registration-date {
      @include caption-1;
      color: $dark-sixth;
      margin-top: 2px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 632px) {
      align-items: flex-start;
      padding-left: 25px;
    }
  }

  .right {
    &__top {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__qualification {
    margin-right: $space-xs;
  }

  &__pv-cv {
    margin-top: 8px;
    background-color: $light-primary;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    p {
      @include caption-1;
      color: $dark-primary;
    }

    span {
      @include caption-1-bold;
    }
  }

  &__pv {
    span {
      color: $money-pv;
    }
  }

  &__cv {
    span {
      color: $money-cv;
    }
  }
}
</style>
