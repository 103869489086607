<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import { eventBus } from "@/main";
import TariffPreview from "@/components/TariffPreview.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: {
    MainButton,
    TariffPreview,
  },

  props: {
    invited: {
      type: Object,
      required: true,
    },

    currentType: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      user: (state) => state.auth.user,
    }),

    formattedName() {
      return `${this.invited.last_name} ${this.invited.first_name} ${this.invited.middle_name}`;
    },

    inviter() {
      if (this.isSelfInvited) return "Лично приглашенный";

      if (this.invited.trade_point_url)
        return this.invited.trade_point_name ?? "ТСП";

      return `${this.invited.inviter_last_name} ${this.invited.inviter_first_name}`;
    },

    formattedDate() {
      return format(new Date(this.invited.person_created_at), "dd.MM.yyyy");
    },

    isSelfInvited() {
      return (
        this.invited.inviter_bin_acc_id === this.selectedBinaryAccount.id &&
        this.currentType !== "tsp" &&
        this.currentType !== "distributor" &&
        this.currentType !== "struct_tsp" &&
        !this.invited?.trade_point_name
      );
    },

    isHideTSPInfo() {
      return (
        !this.isSelfInvited &&
        this.invited.trade_point_url &&
        this.user.tariff?.code !== "vip_d" &&
        this.user.tariff?.code !== "vip_gold_d"
      );
    },

    userImagePath() {
      if (this.invited?.avatar) {
        return this.invited.avatar;
      }

      return require("@/assets/images/user_no_img.svg");
    },
  },

  methods: {
    redirect() {
      if (this.invited.trade_point_url)
        return window.open(this.invited.trade_point_url);

      eventBus.$emit("showInviterBinary", this.invited.inviter_bin_acc_id);
    },
  },
};
</script>

<template>
  <div class="structure-free-item">
    <template v-if="isHideTSPInfo">
      <div class="structure-free-item__left">
        <div class="structure-free-item__img-plug"></div>

        <div class="structure-free-item__user user">
          <p class="user__name-plug" />

          <div class="user__inviter">
            <p>Пригласил: ТСП сеть</p>
          </div>
        </div>
      </div>

      <div class="structure-free-item__right">
        <p class="structure-free-item__tariff-change">
          Доступно на тарифе
          <router-link to="/tariffs">Франшиза или Франшиза Голд</router-link>
        </p>
      </div>
    </template>

    <template v-else>
      <div class="structure-free-item__left">
        <div class="structure-free-item__img">
          <img :src="userImagePath" height="40" alt="" />
        </div>

        <div class="structure-free-item__user user">
          <p class="user__name">{{ formattedName }}</p>

          <template v-if="isSelfInvited">
            <p class="user__self-invited">{{ inviter }}</p>
          </template>

          <template v-else>
            <div class="user__inviter">
              <p>Пригласил:</p>

              <MainButton :title="inviter" :color="'blue'" @click="redirect" />
            </div>
          </template>
        </div>
      </div>

      <div class="structure-free-item__right">
        <TariffPreview
          class="structure-free-item__tariff"
          :tariff-code="'free_c'"
        />

        <p class="structure-free-item__date">Регистрация {{ formattedDate }}</p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.structure-free-item {
  background-color: $light-second;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__tariff-change {
    @include caption-1;
    color: $dark-fifth;
    margin: auto 0;

    > a {
      text-decoration: none;
      color: $blue;
    }
  }

  &__img {
    border-radius: 50%;
    overflow: hidden;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      min-height: 100%;
      min-width: 100%;
    }
  }

  &__img-plug {
    height: 48px;
    width: 48px;
    min-width: 48px;
    background-color: $light-fourth;
    border-radius: 50%;
  }

  .user {
    &__name {
      @include text-2-bold;
      color: $dark-primary;
      margin-bottom: 2px;
    }

    &__name-plug {
      width: 160px;
      height: 16px;
      border-radius: 16px;
      background-color: $light-fourth;
      margin-bottom: 8px;
    }

    &__inviter {
      display: flex;
      align-items: center;

      > p {
        @include caption-1;
        color: $dark-fifth;
        margin-right: 4px;
      }

      .main-button {
        @include caption-1;
        text-align: start;
      }
    }

    &__self-invited {
      @include caption-1;
      color: $dark-fifth;
    }
  }

  &__tariff {
    margin-bottom: 8px;
  }

  &__date {
    @include caption-1;
    color: $dark-fifth;
  }
}

@media (max-width: 900px) {
  .structure-free-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;

    &__left {
      max-width: calc(100% - 80px);
    }

    .user {
      &__inviter {
        flex-wrap: wrap;
      }
    }

    &__tariff {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
</style>
