export default function numberOfLevelsByTariff(tariffCode) {
  switch (tariffCode) {
    case "business_d":
      return 6;
    case "premium_d":
      return 12;
    case "vip_d":
      return 16;
    case "vip_gold_d":
      return 16;
    default:
      return 6;
  }
}
