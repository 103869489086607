<script>
import { mapState, mapActions } from "vuex";
import { eventBus } from "@/main";
import { debounce } from "throttle-debounce";
import getStructureBinaryHeight from "@/package/usecases/get-structure-binary-height";
import vClickOutside from "v-click-outside";
import StructureBinaryItem from "@/components/structure/StructureBinaryItem";
import MainLoader from "@/components/helpers/MainLoader";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";
import MainSearch from "@/components/helpers/MainSearch.vue";
import StructureBinaryPartnerItem from "@/components/structure/StructureBinaryPartnerItem.vue";
import UserPreview from "@/components/UserPreview.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "StructureBinary",

  components: {
    ButtonRedirect,
    StructureBinaryItem,
    MainLoader,
    MainSearch,
    StructureBinaryPartnerItem,
    UserPreview,
    MainButton,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      status: "LOADING",

      params: {
        binaryAccountId: null,
        limit: 3,
      },

      search: "",
      loadingSearch: false,
      showOptions: false,
      showPartners: false,

      structureBinaryMinHeight: 0,
    };
  },

  watch: {
    selectedBinaryAccount: function () {
      this.params.binaryAccountId = this.selectedBinaryAccount.id;
    },

    params: {
      deep: true,
      handler() {
        this.status = "LOADING";

        this.loadBinaryTreeDebounce();
      },
    },
  },

  computed: {
    ...mapState({
      binaryTree: (state) => state.structure.binaryTree,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      binaryListPartners: (state) => state.structure.binaryListPartners,
    }),

    isShowNav() {
      return (
        this.status === "SUCCESS" &&
        this.selectedBinaryAccount.id !== this.params.binaryAccountId
      );
    },

    formattedBinaryListPartners() {
      const binaryListPartners = JSON.parse(
        JSON.stringify(this.binaryListPartners)
      );

      return binaryListPartners.slice(0, 8);
    },

    buttonRedirectText() {
      return this.showPartners ? "Назад к структуре" : "В начало";
    },
  },

  methods: {
    ...mapActions({
      loadBinaryTree: "structure/loadBinaryTree",
      loadBinaryListPartners: "structure/loadBinaryListPartners",
    }),

    loadBinaryTreeDebounce: debounce(1000, function () {
      this.loadBinaryTree(this.params)
        .then(() => {
          this.status = "SUCCESS";

          this.setStructureMinHeight();
        })
        .catch(() => {
          this.status = "ERROR";
        });
    }),

    setBinaryAccountId(id, type) {
      if (this.showOptions) {
        this.showOptions = false;
      }

      if (this.showPartners) {
        this.showPartners = false;
      } else {
        this.params.binaryAccountId = id;
      }

      if (type === "force") {
        this.params.binaryAccountId = id;
      }
    },

    searchPartners() {
      if (this.search.length === 0) {
        return;
      } else {
        this.loadingSearch = true;
        this.showPartners = false;
      }

      this.loadBinaryListPartners({ search: this.search })
        .then(() => {
          this.showOptions = true;
        })
        .finally(() => {
          this.loadingSearch = false;
        });
    },

    hideOptions() {
      this.showOptions = false;
    },

    showAllPartners() {
      this.showOptions = false;
      this.showPartners = true;
    },

    resetSearchResults() {
      this.search = "";
      this.showPartners = false;
    },

    formatUserPreviewInfo(user) {
      return {
        id: user.bin_acc_id,
        name: user.full_name,
        tariff: {
          code: user.tariff_code,
        },
        qualification: {
          code: user.qualification_code,
        },
      };
    },

    setStructureMinHeight() {
      let binaryTree = Object.assign({}, this.binaryTree);
      let countOfGenerations = 0;

      while (binaryTree?.children) {
        countOfGenerations += 1;

        if (binaryTree.children.right && binaryTree.children.right?.children) {
          binaryTree = binaryTree.children.right;
          continue;
        }

        if (binaryTree.children.left && binaryTree.children.left?.children) {
          binaryTree = binaryTree.children.left;
          continue;
        }

        if (binaryTree.children.right) {
          binaryTree = binaryTree.children.right;
          continue;
        }

        if (binaryTree.children.left) {
          binaryTree = binaryTree.children.left;
        }
      }

      this.structureBinaryMinHeight = getStructureBinaryHeight(
        countOfGenerations
      );
    },
  },

  mounted() {
    this.params.binaryAccountId = this.selectedBinaryAccount.id;
  },

  created() {
    eventBus.$on("selectTreeItem", (id) => {
      this.params.binaryAccountId = id;
    });
  },
};
</script>

<template>
  <div class="structure-binary__wrapper">
    <MainSearch
      v-model="search"
      v-click-outside="hideOptions"
      class="structure-binary__search"
      :placeholder="'Поиск в структуре по имени'"
      :loading="loadingSearch"
      :show-options="showOptions"
      :options="binaryListPartners"
      @clear="resetSearchResults"
      @search="searchPartners"
      @showAllResults="showAllPartners"
    >
      <StructureBinaryPartnerItem
        v-for="partner in formattedBinaryListPartners"
        :key="partner.bin_acc_id"
        :partner="partner"
        @click.native="setBinaryAccountId(partner.bin_acc_id)"
      />
    </MainSearch>

    <div v-if="isShowNav" class="structure-binary__nav nav">
      <div class="nav__desktop">
        <ButtonRedirect
          :text="buttonRedirectText"
          :icon="'reply'"
          @click="setBinaryAccountId(selectedBinaryAccount.id)"
        />
        <ButtonRedirect
          v-if="!showPartners"
          :text="'Назад'"
          @click="setBinaryAccountId(binaryTree.parent_bin_acc_id)"
        />
      </div>

      <div class="nav__mobile">
        <MainButton
          :title="buttonRedirectText"
          :padding="'4px 16px'"
          :color="'outlined-grey'"
          @click="setBinaryAccountId(selectedBinaryAccount.id)"
        >
          <template #iconLeft>
            <span class="icon-reply" />
          </template>
        </MainButton>
        <MainButton
          v-if="!showPartners"
          :title="'Назад'"
          :padding="'4px 16px'"
          :color="'outlined-grey'"
          @click="setBinaryAccountId(binaryTree.parent_bin_acc_id)"
        >
          <template #iconLeft>
            <span class="icon-arrow-medium" />
          </template>
        </MainButton>
      </div>
    </div>

    <div v-if="showPartners" class="structure-binary__search-results">
      <p>Найдено по «{{ search }}»</p>

      <UserPreview
        v-for="partner in binaryListPartners"
        :key="partner.bin_acc_id"
        class="structure-binary__user-preview"
        :binary-account="formatUserPreviewInfo(partner)"
        :user="partner"
        :is-show-pv-cv="false"
        @click.native="setBinaryAccountId(partner.bin_acc_id, 'force')"
      />
    </div>

    <div
      v-if="!showPartners"
      class="structure-binary"
      :style="{ 'min-height': `${structureBinaryMinHeight}px` }"
    >
      <template v-if="binaryTree && status === 'SUCCESS'">
        <StructureBinaryItem
          :user="binaryTree"
          :binary-account="selectedBinaryAccount"
          :first-child="true"
          :is-parent="true"
          @changeBinaryAccount="setBinaryAccountId"
        />
      </template>

      <template v-if="status === 'LOADING'">
        <MainLoader />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.structure-binary {
  display: flex;
  justify-content: center;
  margin-top: $space-xxl;

  &__wrapper {
    min-width: 760px;

    .nav {
      &__desktop,
      &__mobile {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      &__mobile {
        display: none;

        ::v-deep .main-button {
          width: 100% !important;
        }
      }

      .icon-reply,
      .icon-arrow-medium {
        background-color: $dark-fifth;
        min-width: 16px;
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      .icon-arrow-medium {
        transform: rotate(180deg);
      }
    }
  }

  &__search {
    max-width: 700px;
    margin-bottom: 24px;
  }

  &__search-results {
    margin-top: $space-xxl;

    p {
      margin-bottom: $space-l;
    }
  }

  &__user-preview {
    cursor: pointer;

    &:not(:last-of-type) {
      margin-bottom: $space-l;
    }
  }

  &__profile {
    margin-bottom: $space-xl;
  }
}

@media (max-width: 1024px) {
  .structure-binary {
    &__wrapper {
      .nav {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 99999;
        background-color: $light-primary;
        padding: 8px 20px;

        &__desktop {
          display: none;
        }

        &__mobile {
          display: flex;
        }
      }
    }
  }
}
</style>
