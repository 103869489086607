<script>
import formatQualification from "@/package/helpers/format-qualification.helpers";
import QualificationPreview from "@/components/QualificationPreview.vue";
import TariffPreview from "@/components/TariffPreview.vue";

export default {
  components: {
    QualificationPreview,
    TariffPreview,
  },

  props: {
    binaryAccount: {
      type: Object,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    img: {
      type: Boolean,
      default: false,
    },

    smallImg: {
      type: Boolean,
      default: false,
    },

    isShowPvCv: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    formattedQualificationCode() {
      return this.binaryAccount?.qualification?.code
        ? this.binaryAccount.qualification.code
        : "";
    },

    formattedTariffCode() {
      return this.binaryAccount?.tariff?.code
        ? this.binaryAccount.tariff.code
        : "";
    },

    formattedCV() {
      return `${this.user.cv_left ?? 0}/${this.user.cv_right ?? 0}`;
    },
  },

  methods: {
    formatQualificationName(qualification) {
      return formatQualification(qualification);
    },
  },
};
</script>

<template>
  <div
    class="user-preview"
    :class="[
      { 'user-preview_small': img },
      { 'user-preview_small-img': smallImg },
    ]"
  >
    <div class="user-preview__img">
      <img v-if="user.avatar" :src="user.avatar" alt="" />

      <img v-else src="../assets/images/user_no_img.svg" alt="" />
    </div>

    <div class="user-preview__wrapper">
      <p class="user-preview__name">{{ binaryAccount.name }}</p>
      <p class="user-preview__id">{{ binaryAccount.id }}</p>
      <div class="user-preview__info">
        <qualification-preview
          class="user-preview__qualification"
          :qualification-code="formattedQualificationCode"
        />

        <tariff-preview
          class="user-preview__tariff"
          :tariff-code="formattedTariffCode"
        />

        <div v-if="isShowPvCv" class="user-preview__pv-cv">
          <p class="user-preview__pv">{{ user.pv ?? 0 }} <span>PV</span></p>
          <p class="user-preview__cv">{{ formattedCV }} <span>CV</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-preview {
  display: flex;
  align-items: center;

  &_small {
    .user-preview__img {
      margin-right: 0;
    }

    .user-preview__info {
      display: none;
    }
  }

  &_small-img {
    .user-preview__img {
      width: 40px;
      height: 40px;
    }
  }

  &__name {
    @include text-2-bold;
    color: $dark-primary;
    margin-bottom: $space-xs;
  }

  &__img {
    margin-right: $space-m;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__id {
    @include text-2;
    color: $dark-fifth;
    margin-bottom: $space-xs;
  }

  &__info {
    display: flex;
  }

  &__qualification {
    margin-right: $space-xs;
  }

  &__tariff {
    background-color: $light-second;
    margin-right: 4px;
  }

  &__pv-cv {
    background-color: $light-second;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;

    p {
      @include caption-1;
      color: $dark-primary;
    }

    span {
      @include caption-1-bold;
    }
  }

  &__pv {
    span {
      color: $money-pv;
    }
  }

  &__cv {
    span {
      color: $money-cv;
    }
  }
}
</style>
