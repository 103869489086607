<script>
import { mapState, mapGetters, mapActions } from "vuex";
import StructureLineItem from "@/components/structure/StructureLineItem";
import MainButton from "@/components/helpers/MainButton";
import MainLoader from "@/components/helpers/MainLoader";
import ButtonArrow from "@/components/helpers/ButtonArrow";

export default {
  components: { MainLoader, StructureLineItem, MainButton, ButtonArrow },

  data() {
    return {
      status: "LOADING",

      params: {
        binaryAccountId: null,
        level: 1,
        page: 1,
      },
    };
  },

  watch: {
    selectedBinaryAccount: function () {
      this.params.binaryAccountId = this.selectedBinaryAccount.id;
      this.params.level = 1;
      this.params.page = 1;
    },

    params: {
      deep: true,
      handler() {
        if (this.params.page === 1) {
          this.status = "LOADING";
        } else {
          this.status = "LOADING-MORE";
        }

        this.loadLinearList(this.params)
          .then(() => {
            this.status = "SUCCESS";
          })
          .catch(() => {
            this.status = "ERROR";
          });
      },
    },
  },

  computed: {
    ...mapState({
      linearList: (state) => state.structure.linearList,
      linearListTotal: (state) => state.structure.linearListTotal,
      linearListLevel: (state) => state.structure.linearListLevel,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    ...mapGetters({
      getSortedLinearList: "structure/getSortedLinearList",
    }),

    hasNextPage() {
      return this.linearList.length < this.linearListTotal;
    },
  },

  methods: {
    ...mapActions({
      loadLinearList: "structure/loadLinearList",
    }),

    changeLevel(type) {
      if (type === "previous") {
        if (this.linearListLevel !== 1) {
          this.params.page = 1;
          this.params.level--;
        }
      }

      if (type === "next") {
        this.params.page = 1;
        this.params.level++;
      }
    },
  },

  mounted() {
    this.params.binaryAccountId = this.selectedBinaryAccount.id;
  },
};
</script>

<template>
  <div class="structure-line__wrapper">
    <div class="structure-line__level level">
      <p class="level__text">Поколение {{ linearListLevel }}</p>

      <div class="level__btns">
        <button-arrow
          class="level__btn"
          :disabled="linearListLevel === 1 || status === 'LOADING'"
          @click.native="changeLevel('previous')"
        />
        <button-arrow
          class="level__btn"
          direction="right"
          :disabled="linearList.length === 0 || status === 'LOADING'"
          @click.native="changeLevel('next')"
        />
      </div>
    </div>

    <div class="structure-line">
      <template v-if="status === 'SUCCESS' || status === 'LOADING-MORE'">
        <structure-line-item
          v-for="user in getSortedLinearList"
          :key="user.id"
          :user="user"
        />
      </template>

      <template v-if="status === 'LOADING' || status === 'LOADING-MORE'">
        <main-loader />
      </template>
    </div>

    <main-button
      v-if="hasNextPage && status !== 'LOADING-MORE'"
      class="structure-line__btn"
      color="dark"
      title="Показать еще"
      @click.native="params.page++"
    />
  </div>
</template>

<style lang="scss" scoped>
.structure-line {
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: $space-xl;
  margin-bottom: $space-xl;

  &__search {
    max-width: 630px;
    margin-bottom: $space-xxl;
  }

  &__level {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    .level {
      &__text {
        @include text-1;
        color: $dark-primary;
      }

      &__btns {
        display: flex;
        margin-left: $space-xl;
      }

      &__btn {
        &:not(:last-child) {
          margin-right: $space-l;
        }
      }
    }
  }

  &__btn {
    max-width: 180px;
  }
}
</style>
