<script>
import { mapState, mapActions } from "vuex";
import { format } from "date-fns";
import { STRUCTURE_FREE_INVITED_TYPES } from "@/package/const/structure-free-invited-types";
import numberOfLevelsByTariff from "@/package/helpers/number-of-levels-by-tariff";
import StructureFreeItem from "@/components/structure/StructureFreeItem.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import MainButtonIcon from "@/components/helpers/MainButtonIcon.vue";

export default {
  components: { StructureFreeItem, MainLoader, MainButton, MainButtonIcon },

  data() {
    return {
      loadingObserver: null,
      page: 1,
      lastPage: 1,
      lastUpdate: null,
      statusInvitedFree: "",

      selectedBlock: "all",

      STRUCTURE_FREE_INVITED_TYPES: STRUCTURE_FREE_INVITED_TYPES,

      isShowButtons: false,
      isShowButtonPrev: false,
      isShowButtonNext: false,
    };
  },

  watch: {
    page() {
      this.loadInvitedFreeRequest();
    },

    selectedBlock() {
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.loadInvitedFreeRequest();
      }
    },

    lastPage() {
      if (
        this.lastPage > 1 &&
        this.loadingObserver === null &&
        this.statusInvitedFree === "SUCCESS"
      ) {
        setTimeout(() => {
          this.setLoadingObserver();
        }, 1000);
      }
    },

    selectedBinaryAccount() {
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.loadInvitedFreeRequest();
      }

      this.loadInvitedFreeCountRequest();
    },

    isShowInvitedList(newValue) {
      if (!newValue) {
        this.loadingObserver = null;
      }
    },

    innerWidth() {
      this.setButtonsVisible();
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      invitedFree: (state) => state.structure.invitedFree,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      invitedFreeCount: (state) => state.structure.invitedFreeCount,
      innerWidth: (state) => state.innerWidth,
    }),

    updatedDate() {
      if (!this.lastUpdate) return;

      return format(new Date(this.lastUpdate), "dd.MM.yyyy, HH:mm");
    },

    level() {
      return numberOfLevelsByTariff(this.selectedBinaryAccount?.tariff?.code);
    },

    isShowInvitedList() {
      if (this.selectedBlock !== "struct_tsp") return true;

      return this.isTariffVipOrVipGold && this.selectedBlock === "struct_tsp";
    },

    filteredBlocks() {
      if (this.isTariffVipOrVipGold) return this.STRUCTURE_FREE_INVITED_TYPES;

      const {
        all,
        self,
        distributor,
        struct_tsp,
      } = this.STRUCTURE_FREE_INVITED_TYPES;

      return {
        all,
        self,
        distributor,
        struct_tsp,
      };
    },

    isTariffVipOrVipGold() {
      return (
        this.selectedBinaryAccount?.tariff?.code === "vip_d" ||
        this.selectedBinaryAccount?.tariff?.code === "vip_gold_d"
      );
    },
  },

  methods: {
    ...mapActions({
      loadInvitedFree: "structure/loadInvitedFree",
      loadInvitedFreeCount: "structure/loadInvitedFreeCount",
    }),

    setButtonsVisible() {
      if (
        this.$refs.structureFreeBlocks?.scrollWidth >
        this.$refs.structureFreeBlocks?.clientWidth
      ) {
        this.isShowButtons = true;
        this.isShowButtonNext = true;
      } else {
        this.isShowButtons = false;
      }
    },

    handleScroll() {
      if (this.$refs.structureFreeBlocks?.scrollLeft > 0) {
        this.isShowButtonPrev = true;
      } else {
        this.isShowButtonPrev = false;
      }

      if (
        this.$refs.structureFreeBlocks.scrollWidth -
          this.$refs.structureFreeBlocks.clientWidth ===
        this.$refs.structureFreeBlocks.scrollLeft
      ) {
        this.isShowButtonNext = false;
      } else {
        this.isShowButtonNext = true;
      }
    },

    scrollList(direction) {
      if (direction === "next") {
        this.$refs.structureFreeBlocks.scrollLeft +=
          this.$refs.structureFreeBlocks.scrollWidth *
          (this.isTariffVipOrVipGold ? 0.2 : 0.25);
      }

      if (direction === "prev") {
        this.$refs.structureFreeBlocks.scrollLeft -=
          this.$refs.structureFreeBlocks.scrollWidth *
          (this.isTariffVipOrVipGold ? 0.2 : 0.25);
      }
    },

    loadInvitedFreeRequest() {
      if (!this.isShowInvitedList) return;

      this.statusInvitedFree = "LOADING";

      this.loadInvitedFree({
        binaryAccount: this.selectedBinaryAccount.id,
        page: this.page,
        type: this.selectedBlock,
      })
        .then((response) => {
          this.lastUpdate = response.data.meta.last_update;

          if (response.data.meta.last_page > 1) {
            this.lastPage = response.data.meta.last_page;
          } else {
            this.lastPage = 1;
          }

          this.statusInvitedFree = "SUCCESS";
        })
        .catch(() => {
          this.statusInvitedFree = "ERROR";
        });
    },

    loadInvitedFreeCountRequest() {
      this.loadInvitedFreeCount({
        binaryAccount: this.selectedBinaryAccount.id,
      }).then(() => {
        for (const [key, value] of Object.entries(
          this.STRUCTURE_FREE_INVITED_TYPES
        )) {
          value.value = this.invitedFreeCount[`${key}_count`];
        }

        this.setButtonsVisible();
      });
    },

    setLoadingObserver() {
      this.loadingObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.page >= this.lastPage) {
              return;
            }
            setTimeout(() => {
              this.page++;
            }, 1000);
          }
        });
      });

      this.loadingObserver.observe(document.querySelector("#history-loader"));
    },
  },

  mounted() {
    this.loadInvitedFreeCountRequest();
    this.loadInvitedFreeRequest();
  },
};
</script>

<template>
  <div class="structure-free">
    <div class="structure-free__blocks blocks">
      <template v-if="isShowButtons">
        <MainButtonIcon
          v-if="isShowButtonPrev"
          class="blocks__button blocks__button_prev"
          @click="scrollList('prev')"
        >
          <span class="icon-arrow-medium" />
        </MainButtonIcon>
        <MainButtonIcon
          v-if="isShowButtonNext"
          class="blocks__button blocks__button_next"
          @click="scrollList('next')"
        >
          <span class="icon-arrow-medium" />
        </MainButtonIcon>
      </template>

      <div
        ref="structureFreeBlocks"
        class="blocks__list"
        :class="{
          blocks__list_four: !isTariffVipOrVipGold,
        }"
        @scroll="handleScroll"
      >
        <div
          v-for="(block, key) in filteredBlocks"
          :key="key"
          class="structure-free__block block"
          :class="{ block_selected: selectedBlock === key }"
          @click="selectedBlock = key"
        >
          <p class="block__text">{{ block.text }}</p>
          <span class="block__value">{{ block.value }}</span>
        </div>
      </div>
    </div>

    <template v-if="isShowInvitedList">
      <div class="structure-free__update">
        <p class="structure-free__updated-date">
          Последнее обновление: {{ updatedDate }}
        </p>
        <p class="structure-free__update-rate">
          Статистика обновляется каждые 4 часа
        </p>
      </div>

      <p class="structure-free__level">Приглашенные до {{ level }} поколения</p>

      <MainLoader v-if="statusInvitedFree === 'LOADING' && page === 1" />

      <div
        v-show="statusInvitedFree !== 'LOADING' || page !== 1"
        class="structure-free__list"
      >
        <StructureFreeItem
          v-for="(user, index) in invitedFree"
          :key="`${index}-${user.person_id}`"
          :invited="user"
          :current-type="selectedBlock"
        />

        <MainLoader
          v-show="page < lastPage && statusInvitedFree !== 'LOADING'"
          id="history-loader"
        />
      </div>
    </template>

    <template v-else>
      <div class="structure-free__tariff-change">
        <p>
          Чтобы увидеть информацию о Клиенты Фри приглашенных ТСП сетью,
          необходим тариф <span>Франшиза</span> или <span>Франшиза Голд</span>
        </p>
        <MainButton
          :title="'Купить тариф'"
          color="gold"
          @click="$router.push({ name: 'Tariffs' })"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.structure-free {
  &__tariff-change {
    max-width: 320px;

    > p {
      @include body-1;
      color: $dark-primary;
      margin-bottom: 32px;

      span {
        @include body-1-bold;
      }
    }
  }

  .blocks {
    position: relative;

    &__list {
      display: grid;
      grid-template-columns: repeat(5, minmax(150px, 1fr));
      column-gap: 12px;
      margin-bottom: 32px;
      overflow: auto;
      scroll-behavior: smooth;
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      &_four {
        grid-template-columns: repeat(4, minmax(150px, 1fr));
      }
    }

    &__button {
      position: absolute;
      top: calc(50% - 16px);
      width: 32px;
      height: 32px;
      background-color: $light-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $shadow-default;
      border-radius: 50%;

      &_prev {
        left: -24px;

        .icon-arrow-medium {
          transform: rotate(180deg);
        }
      }

      &_next {
        right: -24px;
      }

      @media (max-width: 425px) {
        &_prev {
          left: -12px;
        }

        &_next {
          right: -12px;
        }
      }
    }

    .icon-arrow-medium {
      width: 20px;
      height: 20px;
      background-color: $dark-sixth;
    }
  }

  .block {
    background-color: $light-primary;
    border: 1px solid $light-third;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: border 0.1s linear;

    &__text {
      @include caption-1;
      color: $dark-third;
      margin-bottom: 4px;
    }

    &__value {
      @include text-2-bold;
      color: $dark-primary;
    }

    &_selected {
      border: 1px solid $gold-dark;
    }
  }

  &__update {
    margin-bottom: 32px;
  }

  &__updated-date {
    @include text-2;
    color: $dark-primary;
    margin-bottom: 4px;
  }

  &__update-rate {
    @include caption-1;
    color: $dark-fifth;
  }

  &__level {
    @include text-2;
    color: $dark-fifth;
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .structure-free {
    &__blocks {
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 900px) {
  .structure-free {
    &__blocks {
      // grid-template-columns: repeat(2, 1fr);
      // gap: 16px;
    }
  }
}
</style>
