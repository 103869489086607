export default function getStructureBinaryHeight(countOfGenerations) {
  switch (countOfGenerations) {
    case 0:
      return 200;
    case 1:
      return 380;
    case 2:
      return 460;
    case 3:
      return 550;
    default:
      return 550;
  }
}
