<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },

    selectedTab: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="structure-binary-tabs">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      class="structure-binary-tabs__item"
      :class="{
        'structure-binary-tabs__item_selected': selectedTab.id === tab.id,
      }"
      @click="$emit('update:selectedTab', tab)"
    >
      <span class="structure-binary-tabs__icon" :class="tab.icon"></span>
      <p>{{ tab.title }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.structure-binary-tabs {
  background-color: $light-second;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 220px;
  height: 40px;

  &__item {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dark-primary;
    cursor: pointer;
    transition: background-color 0.2s linear;
    @include text-2;

    &_selected {
      background-color: $dark-primary;
      color: $light-second;

      .structure-binary-tabs__icon {
        background-color: $light-second;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    background-color: $dark-primary;
    margin-right: 8px;
  }
}
</style>
