<template>
  <button
    class="button-arrow"
    :class="{ 'button-arrow--right': direction === 'right' }"
    :disabled="disabled"
  >
    <img src="../../assets/icons/arrow.svg" alt="" />
  </button>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: "left",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-arrow {
  height: 32px;
  width: 32px;
  background-color: $background-grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  cursor: pointer;

  &--right {
    img {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
