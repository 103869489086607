<script>
import QualificationPreview from "@/components/QualificationPreview.vue";
import TariffPreview from "@/components/TariffPreview.vue";

export default {
  components: { QualificationPreview, TariffPreview },

  props: {
    partner: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedPartnerImg() {
      return this.partner.avatar
        ? this.partner.avatar
        : require("../../assets/images/user_no_img.svg");
    },
  },
};
</script>

<template>
  <div class="structure-binary-partner-item">
    <div class="structure-binary-partner-item__partner partner">
      <div class="partner__img">
        <img :src="formattedPartnerImg" alt="" />
      </div>
      <p class="partner__name">{{ partner.full_name }}</p>
    </div>

    <div class="structure-binary-partner-item__info info">
      <qualification-preview :qualification-code="partner.qualification_code" />

      <tariff-preview
        class="info__tariff-preview"
        :tariff-code="partner.tariff_code"
      />
    </div>
  </div>
</template>

<style lang="scss">
.structure-binary-partner-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-bottom: $space-l;
  }

  .tariff-preview {
    background-color: $light-second;
  }

  .partner {
    display: flex;
    align-items: center;

    &__img {
      min-width: 32px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: $space-s;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      @include text-2;
      color: $dark-primary;
    }
  }

  .info {
    display: flex;
    align-items: center;

    &__tariff-preview {
      margin-left: $space-xs;
    }
  }
}
</style>
